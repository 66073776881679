import {
  GET_UNIT_LIST_REQUEST,
  GET_UNIT_LIST_SUCCESS,
  GET_UNIT_LIST_FAILURE,
  GET_UNIT_DETAILS_REQUEST,
  GET_UNIT_DETAILS_FAILURE,
  GET_UNIT_DETAILS_SUCCESS,
  GET_UNIT_STATUS_REQUEST,
  GET_UNIT_STATUS_FAILURE,
  GET_UNIT_STATUS_SUCCESS,
  CLEAR_UNIT_STATUS,
  GET_UNIT_EVENTS_LIST_REQUEST,
  GET_UNIT_EVENTS_LIST_FAILURE,
  GET_UNIT_EVENTS_LIST_SUCCESS,
  GET_UNIT_PICTURE_DETAILS_SUCCESS,
  GET_UNIT_DEBUG_REQUEST,
  GET_UNIT_DEBUG_SUCCESS,
  GET_UNIT_DEBUG_FAILURE,
  UNIT_COMMAND_REQUEST,
  UNIT_COMMAND_FAILURE,
  UNIT_COMMAND_SUCCESS,
  GET_UNIT_LOGS_REQUEST,
  GET_UNIT_LOGS_SUCCESS,
  GET_UNIT_LOGS_FAILURE,
  CLEAR_UNIT_DEBUG,
  GET_UNIT_OPERATION_LIST_REQUEST,
  GET_UNIT_OPERATION_LIST_SUCCESS,
  GET_UNIT_OPERATION_LIST_FAILURE,
  GET_UNIT_DETAILS_MORE_REQUEST,
  GET_UNIT_DETAILS_MORE_SUCCESS,
  GET_UNIT_DETAILS_MORE_FAILURE,
  POST_UNIT_OPERATION_REQUEST,
  POST_UNIT_OPERATION_FAILURE,
  POST_UNIT_OPERATION_SUCCESS,
  DELETE_UNIT_OPERATION_REQUEST,
  DELETE_UNIT_OPERATION_SUCCESS,
  DELETE_UNIT_OPERATION_FAILURE,
  GET_UNIT_ENERGY_USAGE_SUMMARY_REQUEST,
  GET_UNIT_ENERGY_USAGE_SUMMARY_SUCCESS,
  GET_UNIT_ENERGY_USAGE_SUMMARY_FAILURE,
  GET_UNIT_ENERGY_USAGE_HISTORY_REQUEST,
  GET_UNIT_ENERGY_USAGE_HISTORY_SUCCESS,
  GET_UNIT_ENERGY_USAGE_HISTORY_FAILURE,
} from "actions/unit";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { isEmpty, orderBy } from "lodash";
import { constructDebugBucket } from "utils/unit";

dayjs.extend(advancedFormat);

const initialSummary = {
  battery_import: 0,
  battery_export: 0,
  solar: 0,
  grid_import: 0,
  grid_export: 0,
  house: 0,
  solar_gen: 0,
  grid_avoided: 0,
  peak_avoided: 0,
  battery_usage: 0,
  grid_usage: 0,
  solar_usage: 0,
  peak: 0,
  off_peak: 0,
  shoulder: 0,
  carbon_use: 0,
  car_use: 0,
  phone_use: 0,
  timestamp: "",
};

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
  status: {},
  pictureDetails: {},
  events: [],
  eventsLoading: false,
  debug: {},
  debugLoading: false,
  commandLoading: false,
  logList: [],
  log: {},
  logLoading: false,
  operationList: [],
  operationLoading: false,
  unitDetailsMore: {},
  summaryLoading: false,
  summary: initialSummary,
  historyLoading: false,
  history: [],
};

export default function unit(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UNIT_DETAILS_REQUEST:
    case GET_UNIT_LIST_REQUEST:
    case GET_UNIT_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_UNIT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: orderBy(payload, ["serial"]).map((it) => {
          return {
            ...it,
            value: it.id,
            label: it.serial,
          };
        }),
      };
    }

    case GET_UNIT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case GET_UNIT_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: payload,
        pictureDetails: {
          solar: payload?.solar_power,
          battery: payload?.battery_power,
          grid: payload?.grid_power,
          grid_present: payload?.grid_present,
          online: payload?.online,
          soc: payload?.battery_soc,
        },
      };
    }

    case GET_UNIT_LIST_FAILURE:
    case GET_UNIT_DETAILS_FAILURE:
    case GET_UNIT_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_UNIT_PICTURE_DETAILS_SUCCESS: {
      return {
        ...state,
        status: payload,
        pictureDetails: {
          solar: payload?.solar_power,
          battery: payload?.battery_power,
          grid: payload?.grid_power,
          grid_present: payload?.grid_present,
          online: payload?.online,
          soc: payload?.battery_soc,
        },
      };
    }

    case CLEAR_UNIT_STATUS: {
      return {
        ...state,
        status: {},
        log: {},
        logList: [],
        details: {},
        pictureDetails: {},
        debug: {},
        operationList: [],
        unitDetailsMore: {},
      };
    }

    case CLEAR_UNIT_DEBUG: {
      return {
        ...state,
        debug: {},
      };
    }

    case GET_UNIT_EVENTS_LIST_REQUEST: {
      return {
        ...state,
        eventsLoading: true,
      };
    }

    case GET_UNIT_EVENTS_LIST_SUCCESS: {
      return {
        ...state,
        eventsLoading: false,
        events: orderBy(payload, ["timestamp"], "desc").map((it, i) => {
          return {
            ...it,
            id: i,
            stopped: it.timestamp,
          };
        }),
      };
    }

    case GET_UNIT_EVENTS_LIST_FAILURE: {
      return {
        ...state,
        eventsLoading: false,
      };
    }

    case GET_UNIT_DEBUG_REQUEST: {
      return {
        ...state,
        debugLoading: true,
      };
    }

    case GET_UNIT_DEBUG_SUCCESS: {
      return {
        ...state,
        debugLoading: false,
        debug: constructDebugBucket(payload),
      };
    }

    case GET_UNIT_DEBUG_FAILURE: {
      return {
        ...state,
        debugLoading: false,
      };
    }

    case UNIT_COMMAND_REQUEST: {
      return {
        ...state,
        commandLoading: true,
      };
    }

    case UNIT_COMMAND_SUCCESS: {
      return {
        ...state,
        commandLoading: false,
      };
    }

    case UNIT_COMMAND_FAILURE: {
      const { source } = payload;
      const obj =
        source === "log"
          ? {
              log: {
                error: "",
              },
            }
          : source === "debug"
          ? {
              debug: {
                error: "",
              },
            }
          : {};

      return {
        ...state,
        commandLoading: false,
        ...obj,
      };
    }

    case GET_UNIT_LOGS_REQUEST: {
      return {
        ...state,
        logLoading: true,
      };
    }

    case GET_UNIT_LOGS_SUCCESS: {
      return {
        ...state,
        logLoading: false,
        log: payload?.log ?? {},
        logList:
          payload?.log?.list.map((it, i) => {
            return {
              ...it,
              id: i,
              message: it.Message,
              severity: it.Severity,
              source: it.Source,
              timestamp: it.Timestamp,
            };
          }) ?? [],
      };
    }

    case GET_UNIT_LOGS_FAILURE: {
      return {
        ...state,
        logLoading: false,
      };
    }

    case POST_UNIT_OPERATION_REQUEST:
    case DELETE_UNIT_OPERATION_REQUEST:
    case GET_UNIT_OPERATION_LIST_REQUEST: {
      return {
        ...state,
        operationLoading: true,
      };
    }

    case GET_UNIT_OPERATION_LIST_SUCCESS: {
      return {
        ...state,
        operationLoading: false,
        operationList: payload,
      };
    }

    case POST_UNIT_OPERATION_SUCCESS:
    case POST_UNIT_OPERATION_FAILURE:
    case DELETE_UNIT_OPERATION_SUCCESS:
    case DELETE_UNIT_OPERATION_FAILURE:
    case GET_UNIT_OPERATION_LIST_FAILURE: {
      return {
        ...state,
        operationLoading: false,
      };
    }

    case GET_UNIT_DETAILS_MORE_REQUEST: {
      return {
        ...state,
      };
    }

    case GET_UNIT_DETAILS_MORE_SUCCESS: {
      return {
        ...state,
        unitDetailsMore: payload,
      };
    }

    case GET_UNIT_DETAILS_MORE_FAILURE: {
      return {
        ...state,
      };
    }

    case GET_UNIT_ENERGY_USAGE_SUMMARY_REQUEST: {
      return {
        ...state,
        summaryLoading: true,
      };
    }

    case GET_UNIT_ENERGY_USAGE_SUMMARY_SUCCESS: {
      return {
        ...state,
        summaryLoading: false,
        summary: isEmpty(payload) ? initialSummary : payload,
      };
    }

    case GET_UNIT_ENERGY_USAGE_SUMMARY_FAILURE: {
      return {
        ...state,
        summaryLoading: false,
      };
    }

    case GET_UNIT_ENERGY_USAGE_HISTORY_REQUEST: {
      return {
        ...state,
        historyLoading: true,
      };
    }

    case GET_UNIT_ENERGY_USAGE_HISTORY_SUCCESS: {
      return {
        ...state,
        historyLoading: false,
        history: payload,
      };
    }

    case GET_UNIT_ENERGY_USAGE_HISTORY_FAILURE: {
      return {
        ...state,
        historyLoading: false,
      };
    }

    default:
      return state;
  }
}
